<template>
  <div class="receives animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>مدیریت نامه‌های دریافتی</h3>
          </v-col>
          <v-col>
            <v-btn
              class="primary-btn"
              style="float: left"
              @click="
                openAddDialog();
                uploadedFiles = [];
              "
            >
              <v-icon left>add</v-icon>
              <h6 class="mt-1">ثبت نامه دریافتی جدید</h6>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <hr />
      <v-card class="cards pa-3 mt-6">
        <v-card-text>
          <div>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mt-2 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="موردی برای نمایش وجود ندارد"
              empty-filtered-text="موردی برای نمایش وجود ندارد"
              :busy="Busy"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
              @row-clicked="onRowSelected"
              tbody-tr-class="trClass"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(amount)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ Number(data.value).toLocaleString() }}
                </div>
              </template>
              <template v-slot:cell(transactionFee)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ Number(data.value).toLocaleString() }}
                </div>
              </template>
              <template v-slot:cell(subject)="data">
                <div style="text-align: center; vertical-align: middle">
                    <v-tooltip
                    top
                    max-width="600"
                    v-if="deviceType != 'mobile'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{
                          data.value.length > 20
                            ? data.value.slice(0, 20) + "..."
                            : data.value
                        }}
                      </span>
                    </template>
                    <p class="text-right p-2 text-justify">{{ data.value }}</p>
                  </v-tooltip>
                  <p v-else>
                    {{
                      data.value.length > 20
                        ? data.value.slice(0, 20) + "..."
                        : data.value
                    }}
                  </p>
                </div>
              </template>
              <!--<template v-slot:cell(file)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn
                    @click="openFile(data.item)"
                    class="btn-primary pa-2 mx-2"
                    ><v-icon color="white">mdi-file</v-icon></v-btn
                  >
                  <a id="download" target="blank"></a>
                </div>
              </template>-->
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="addReceivedLetterDialog" width="600">
      <v-card
        class="pa-3 modal-card"
        style="
          max-height: 600px !important;
          overflow-y: scroll;
          overflow-x: hidden;
        "
      >
        <v-card-title class="mb-2">
          <h4>ثبت نامه دریافتی جدید</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2"
            @click="addReceivedLetterDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="receivedDetail.letterNumber"
            label="شماره نامه"
            outlined
            dense
          ></v-text-field>

          <v-row class="time-row">
            <v-col>
              <v-text-field
                v-model="receivedDetail.sender"
                label="فرستنده"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-text-field
                v-model="receivedDetail.receiver"
                label="گیرنده"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col cols="12">
              <span id="newDate">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="receivedDetail.newDate"
                  label=" تاریخ "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="receivedDetail.newDate"
                element="newDate"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col cols="12">
              <span id="newTime">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="mdi-clock-outline"
                  v-model="receivedDetail.newTime"
                  label=" ساعت "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="receivedDetail.newTime"
                element="newTime"
                type="time"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-text-field
                v-model="receivedDetail.subject"
                label="موضوع"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-textarea
                v-model="receivedDetail.answer"
                label="پاسخ احتمالی"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <div class="mt-6">
            <v-file-input
              @change="myUpload(receivedDetail.file)"
              v-model="receivedDetail.file"
              chips
              outlined
              dense
              label="بارگذاری فایل"
              prepend-inner-icon="upload_file"
              prepend-icon=""
              class="file-input"
            ></v-file-input>
            <v-progress-linear
              rounded
              v-if="myFile.showProgress"
              height="10"
              :value="myFile.uploadPercentage"
              class="mb-4 mt-0"
            >
            </v-progress-linear>
          </div>
          <v-row>
            <div
              v-if="myFile.isUploaded"
              color="green"
              class="my-2 px-3"
              style="font-size: 16px"
            >
              فایل با موفقیت بارگذاری شد.
            </div>
          </v-row>
          <div v-if="uploadedFiles.length" class="files-box d-flex flex-column">
            <div class="files-title">
              لیست فایل‌ها ({{ uploadedFiles.length }})
            </div>
            <span
              v-for="(item, index) in uploadedFiles"
              :key="index"
              class="mb-2 pt-2"
            >
              <span @click="deleteFile(index)" class="pointer">
                <v-icon class="red--text text--lighten-1 pe-2"
                  >mdi-close-circle-outline</v-icon
                >
              </span>
              <a :href="item.url" target="blank">{{ showName[index] }}</a>
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="primary-btn"
            outlined
            :disabled="computedAddBtn"
            @click="addToReceivedList()"
          >
            ثبت
          </v-btn>
          <v-btn
            class="red-btn"
            outlined
            @click="addReceivedLetterDialog = false"
          >
            لغو
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="receivedItemDialog" width="600">
      <v-card class="modal-card pa-3" v-if="!edited">
        <v-card-title class="mb-2">
          <h4>مشاهده جزییات نامه</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 ms-2"
            @click="
              receivedItemDialog = false;
              edited = false;
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col>
              <span class="h2 primary--text">شماره نامه:</span>
              <span class="h2"> {{ selected.letterNumber }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">تاریخ:</span>
              <span class="h2"> {{ selected.date }}</span>
            </v-col>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">ساعت:</span>
              <span class="h2"> {{ selected.time }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">فرستنده:</span>
              <span class="h2"> {{ selected.sender }}</span>
            </v-col>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">گیرنده :</span>
              <span class="h2"> {{ selected.receiver }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">نام ثبت کننده:</span>
              <span class="h2">
                {{
                  selected.registerentName ? selected.registerentName : "-"
                }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">موضوع :</span>
              <span class="h2 mt-1" style="line-height: 24px">
                {{ selected.subject }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">پاسخ احتمالی :</span>
              <p class="h2 mt-1 text-justify" style="line-height: 24px">
                {{ selected.answer ? selected.answer : "-" }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <div v-if="selected.files">
                <span class="h2 primary--text">
                  فایل‌ها ({{ selected.files.length }}):</span
                >
                <div
                  class="d-flex flex-column mt-4 px-3"
                  v-if="selected.files.length"
                >
                  <li
                    v-for="(item, index) in selected.files"
                    :key="index"
                    class="pb-4"
                    style="font-size: 14px"
                  >
                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                  </li>
                </div>
                <span v-else class="h3 px-2">فایلی آپلود نشده است.</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn
          @click="
            edited = true;
            uploadedFiles = [];
            myFile.showProgress = false;
            myFile.isUploaded = false;
          "
          class="secondary-btn mb-4 me-5 pa-5"
        >
          <v-icon left>edit</v-icon>
          ویرایش</v-btn
        >
      </v-card>
      <v-card
        v-else
        class="modal-card"
        style="
          max-height: 600px !important;
          overflow-y: scroll;
          overflow-x: hidden;
        "
      >
        <v-card-title class="mb-2">
          <h4>ویرایش نامه</h4>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3 time-row">
            <v-col>
              <div>
                <v-text-field
                  outlined
                  dense
                  type="text"
                  v-model="selectedEdit.letterNumber"
                  label=" شماره نامه "
                >
                </v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <span id="newDate2">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="selectedEdit.date"
                  label=" تاریخ "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>
              <date-picker
                v-model="selectedEdit.date"
                element="newDate2"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <span id="newTime2">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="mdi-clock-outline"
                  v-model="selectedEdit.time"
                  label="ساعت"
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.time"
                element="newTime2"
                color="#00a7b7"
                type="time"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <!-- <v-select
                v-model="selectedEdit.sender"
                :items="employees"
                item-text="text"
                item-value="value"
                label="فرستنده"
                outlined
                dense
              ></v-select> -->
              <v-text-field
                outlined
                dense
                type="text"
                v-model="selectedEdit.sender"
                label=" فرستنده"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <!-- <v-select
                v-model="selectedEdit.receiver"
                :items="employees"
                item-text="text"
                item-value="value"
                label="گیرنده"
                outlined
                dense
              ></v-select> -->
              <v-text-field
                outlined
                dense
                type="text"
                v-model="selectedEdit.receiver"
                label=" گیرنده"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-text-field
                outlined
                dense
                type="text"
                v-model="selectedEdit.subject"
                label="موضوع "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-textarea
                outlined
                dense
                type="text"
                v-model="selectedEdit.answer"
                label="پاسخ احتمالی"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <p class="text-right mb-2">
                * شما میتوانید بیش از یک فایل انتخاب کنید.
              </p>
              <v-file-input
                @change="editedUpload(file)"
                v-model="file"
                chips
                outlined
                dense
                label=" بارگذاری فایل"
                prepend-inner-icon="upload_file"
                prepend-icon=""
                class="file-input mb-6"
              ></v-file-input>
              <v-progress-linear
                rounded
                v-if="myFile.showProgress"
                height="10"
                :value="myFile.uploadPercentage"
                class="mb-4 mt-0"
              >
              </v-progress-linear>
              <v-row>
                <div
                  v-if="myFile.isUploaded"
                  color="green"
                  class="mt-3 mb-1 pa-3"
                  style="font-size: 16px"
                >
                  فایل با موفقیت بارگذاری شد.
                </div>
              </v-row>
            </v-col>
          </v-row>
          <div
            v-if="selectedEdit.files.length"
            class="files-box d-flex flex-column mb-6"
          >
            <div class="files-title">
              فایل‌های آپلود شده ({{ selectedEdit.files.length }})
            </div>
            <span
              v-for="(item, index) in selectedEdit.files"
              :key="index"
              class="mb-2 pt-2"
            >
              <span @click="deleteFile2(index)" class="pointer">
                <v-icon class="red--text text--lighten-1 pe-2"
                  >mdi-close-circle-outline</v-icon
                >
              </span>
              <a :href="item.url" target="blank">{{ item.name }}</a>
            </span>
          </div>

          <v-btn
            @click="editReceivedItem()"
            :disabled="computedEditBtn"
            class="primary-btn mt-4 mx-2"
            >ثبت</v-btn
          >
          <v-btn @click="edited = false" class="red-btn mt-4">لغو</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: true,
      Fields: [
        { key: "index", label: "#" },
        { key: "letterNumber", label: "شماره نامه" },
        { key: "sender", label: "فرستنده" },
        { key: "receiver", label: "گیرنده" },
        { key: "date", label: "تاریخ" },
        { key: "time", label: "ساعت" },
        { key: "subject", label: "موضوع" },
        { key: "fileIds", label: "تعداد فایل‌ها" },
        // { key: "operation", label: "عملیات" },
      ],
      Items: [],
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      addReceivedLetterDialog: false,
      types: [
        { value: "hourly", text: "ساعتی" },
        { value: "daily", text: "روزانه" },
      ],
      states: [
        { value: "eligible", text: "استحقاقی" },
        { value: "incentive", text: "تشویقی" },
        { value: "withoutPay", text: "بدون حقوق" },
        { value: "sick", text: "استعلاجی" },
      ],
      status: [
        { value: "registered", text: "ثبت اولیه" },
        { value: "approved", text: "تایید درخواست" },
        { value: "rejected", text: "رد درخواست" },
      ],
      showProgress: false,
      uploadButDisable: false,
      variant: "info",
      striped: true,
      max: 100,
      uploadPercentage: 0,
      isUploaded: false,
      file: [],
      reportFiles: [],
      request: {},
      vLoading: false,

      myFile: new Object({
        uploadPercentage: 0,
      }),
      fileBusy: false,
      fileFilter: "",
      receivedDetail: {},
      employees: [],
      employeeBusy: false,
      selected: {},
      edited: false,
      receivedItemDialog: false,
      selectedEdit: {},
      uploadedFile: "",
      showName: [],
      financialId: "",
      fileId: "",
      showBig: false,
      letterId: "",
      editedFileId: [],
      uploadedFiles: [],
      fileIds: [],
      deviceType: "",
    };
  },

  methods: {
    openAddDialog() {
      this.addReceivedLetterDialog = true;
      this.receivedDetail = {};
      this.isUploaded = false;
      this.myFile = new Object({
        uploadPercentage: 0,
      });
      this.getEmployeesList();
    },
    //openFile(item) {
    //  this.$http
    //    .post(
    //      this.baseUrl + "/clinic/secretariat/letter/show",
    //      {
    //        letterId: item.id,
    //      },
    //      {
    //        headers: {
    //          Authorization: "Bearer: " + localStorage.getItem("token"),
    //        },
    //      }
    //    )
    //    .then((res) => {
    //      this.loading = false;
    //      if (res.status == 200) {
    //        this.selected.file = res.data.files[0].url;
    //        var button = document.getElementById("download");
    //        button.setAttribute("href", this.selected.file);
    //        button.click();
    //      } else {
    //        this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
    //      }
    //    })
    //    .catch((err) => {
    //      this.toast("خطا: " + err.response.data, "error");
    //      this.loading = false;
    //    });
    //  this.showBig = true;
    //},
    getEmployeesList() {
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employees/list",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.employeeBusy = false;
          if (res.status == 200) {
            this.employees = res.data.map((x) => {
              return {
                id: x.id,
                text: x.name,
              };
            });
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    getReceivedLettersList() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/secretariat/letters/receiveList",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          this.vLoading = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.Busy = false;
        });
    },
    addToReceivedList() {
      this.$http
        .post(
          this.baseUrl + "/clinic/secretariat/letter/newReceive",
          {
            sender: this.receivedDetail.sender,
            date: this.receivedDetail.newDate + this.receivedDetail.newTime,
            receiver: this.receivedDetail.receiver,
            letterNumber: this.receivedDetail.letterNumber,
            subject: this.receivedDetail.subject,
            fileIds: this.fileIds,
            answer: this.receivedDetail.answer,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.toast(res.data, "success");
            this.addReceivedLetterDialog = false;
            this.receivedDetail = {};
            this.isUploaded = false;
            this.myFile = new Object({
              uploadPercentage: 0,
            });
            this.uploadedFiles = [];
            this.fileIds = [];
            this.showName = [];
            this.getReceivedLettersList();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.addReceivedLetterDialog = false;
            this.isUploaded = false;
            this.myFile = new Object({
              uploadPercentage: 0,
            });
            this.uploadedFiles = [];
            this.fileIds = [];
            this.showName = [];
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.addReceivedLetterDialog = false;
          this.isUploaded = false;
          this.myFile = new Object({
            uploadPercentage: 0,
          });
          this.uploadedFiles = [];
          this.fileIds = [];
          this.showName = [];
        });
    },
    myUpload(file) {
      // NOTE (m-myUpload) to upload files

      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      this.showName.push(file.name);
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/secretariat/letter/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.myFile.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.uploadedFiles.push(d.data);
            this.fileIds.push(d.data.id);
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    deleteFile(index) {

      this.uploadedFiles.splice(index, 1);
      this.showName.splice(index, 1);
      this.fileIds.splice(index, 1);
    },
    deleteFile2(index) {
      this.selectedEdit.files.splice(index, 1);
      this.selectedEdit.fileIds.splice(index, 1);
    },
    editedUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      this.showName.push(file.name);
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/secretariat/letter/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.myFile.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.editedFileId = [];
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            //this.selectedEdit.files[0] = d.data;
            //this.selectedEdit.files[0].name = this.showName;
            this.selectedEdit.files.push(d.data);
            let filesLength = this.selectedEdit.files.length;
            let namesLength = this.showName.length;
            this.selectedEdit.files[filesLength - 1].name =
              this.showName[namesLength - 1];
            this.selectedEdit.fileIds.push(d.data.id);
            this.uploadedFiles = [];
            this.fileIds = [];
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.uploadedFiles = [];
          this.fileIds = [];
        });
    },
    editReceivedItem() {
      this.$http
        .post(
          this.baseUrl + "/clinic/secretariat/letter/editReceive",
          {
            letterId: this.letterId,
            date: this.selectedEdit.date + this.selectedEdit.time,
            letterNumber: this.selectedEdit.letterNumber,
            subject: this.selectedEdit.subject,
            sender: this.selectedEdit.sender,
            receiver: this.selectedEdit.receiver,
            fileIds: this.selectedEdit.fileIds,
            answer: this.selectedEdit.answer,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.edited = false;
            this.selected = this.selectedEdit;
            this.isUploaded = false;
            this.myFile = new Object({
              uploadPercentage: 0,
            });
            this.uploadedFiles = [];
            this.fileIds = [];
            this.showName = [];
            this.getReceivedLettersList();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.edited = false;
            this.uploadedFiles = [];
            this.fileIds = [];
            this.showName = [];
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.edited = false;
          this.uploadedFiles = [];
          this.fileIds = [];
        });
    },
    onRowSelected(item) {
      //   this.selected = JSON.parse(JSON.stringify(item));

      this.letterId = item.id;
      this.edited = false;
      this.selected = item;
      this.loading = true;

      this.$http
        .post(
          this.baseUrl + "/clinic/secretariat/letter/show",
          {
            letterId: item.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.loading = false;
          if (res.status == 200) {
            this.selected = JSON.parse(JSON.stringify(res.data));
            this.selectedEdit = JSON.parse(JSON.stringify(this.selected));
            //this.selected.files.length
            //  ? (this.fileId = this.selected.files[0].id)
            //  : "";
            //this.editedFileId = this.selected.fileIds;

            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
            this.receivedItemDialog = true;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.loading = false;
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  computed: {
    computedAddBtn() {
      if (
        this.receivedDetail.letterNumber &&
        this.receivedDetail.sender &&
        this.receivedDetail.receiver &&
        this.receivedDetail.subject &&
        this.receivedDetail.newDate &&
        this.uploadedFiles.length
      ) {
        return false;
      } else return true;
    },
    computedEditBtn() {
      if (
        this.selectedEdit.letterNumber &&
        this.selectedEdit.sender &&
        this.selectedEdit.receiver &&
        this.selectedEdit.subject &&
        this.selectedEdit.date &&
        this.selectedEdit.files.length > 0
      ) {
        return false;
      } else return true;
    },
  },
  mounted() {
     if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
    this.vLoading = true;
    this.getReceivedLettersList();
    //this.getEmployeesList();
  },
};
</script>
<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 82%;
}
.receives {
  .trClass {
    cursor: pointer;
    &:hover {
      background-color: rgb(236, 236, 236);
    }
    &.table-active {
      background-color: #fff !important;
    }
  }
}
.modal-card {
  .cardInfo-box {
    border-right: 2px solid #ccc;
    width: fit-content !important;
  }
  .v-file-input .v-file-input__text.v-file-input__text--chips {
    display: none !important;
  }
  .v-file-input {
    .mdi-close::before {
      display: none;
    }
  }
  .files-box {
    border: 1px solid rgb(172, 172, 172);
    text-align: right;
    font-size: 14px;
    margin-top: 35px;
    padding: 10px;
    border-radius: 7px;
    position: relative;
    ul {
      li {
        text-decoration: none;
      }
    }
    .files-title {
      position: absolute;
      top: -9px;
      right: 20px;
      background-color: #fff;
      font-size: 14px;
      padding: 0 6px;
    }
  }
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 80% !important;
}
</style>
